@import '~@reasoncorp/kyber-js/dist/scss/themes/misuite-v2/theme-misuite-v2';
@import "./Faq";
@import "./Login";

.ApplicationCard {
  img {
    width: 100px;
    height: 100px;
  }
}

p.text-danger:focus {
  outline: auto map-get($theme-colors, danger)
}

.Notifications {
  .form-group {
    margin-bottom: 0;
  }

  .Paginator .col:first-child {
    padding-left: 2rem;
  }
}