body:has(.Login) {
  background: #0B2E54;

  .Footer {
    background: #f7f7f7 !important;

    .footer-brand, a {
      color: #000 !important;
    }
  }
}

@media(max-width: 1200px) {
  .Login .info-column {
    min-height: 10rem;
    background-size: 10rem !important;
    background: url('../images/misuite/mi-login.svg') no-repeat center;
    background-position-x: center !important;

    .welcome-text {
      position: absolute;
      width: 100%;
      left: 0;
      bottom: 0;
    }

    h1 {
      font-size: 2rem !important;
    }

    h2 {
      font-size: 3.0rem !important;
    }
  }
}

@media(min-width: 1200px) {
  .Login .info-column {
    background-size: 30rem !important;
    background-position-x: 10% !important;
    margin-left: -2rem;
    margin-right: 1rem;

    .welcome-text {
      position: absolute;
      width: 100%;
      left: calc(0% - 2rem);
      top: calc(50% - 5.25rem);
    }

    h1 {
      font-size: 2.5rem !important;
    }

    h2 {
      font-size: 5rem !important;
    }
  }
}

.Login {
  .Login-Centered {
    max-width: 950px;
  }

  .info-column {
    background: url('../images/misuite/mi-login.svg') no-repeat;
    background-position-y: center;
  }

  .form-column {
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;

    .card {
      box-shadow: 0 12px 25px 0 rgba(0, 0, 0, 0.5) !important;
      background: #FFF;
    }

    h3 {
      color: $mi-secondary-color;
    }
  }

}

.login-error {
  background: rgba(255, 0, 0, 0.1);
}