.Faq {
  .Faq-header {
    background: #0B2E54 url('../images/misuite/mi-login.svg') no-repeat center;
    background-size: 8rem;
    padding: 4rem;
    margin-top: -20px;
  }

  .card {
    box-shadow: none !important;
    border: 1px solid rgba(0, 0, 0, 0.125) !important;

    .card-body {
      font-size: 1rem;
    }

    .card-header {
      font-size: 1.25rem;
      background: #FFFFFF;
      color: #444;
      font-weight: 600;
    }
  }

  .card.faq-section-closed .card-header {
    border: none !important;
    border-radius: 0.25rem;
  }
}